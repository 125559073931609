<template>
  <div>
    <div class="mb-40">
      <a-form-model
        ref="container"
        :model="model"
        :rules="rules"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 11 }"
        labelAlign="left"
        :colon="false"
      >
        <div class="mb-40">
          <div class="mb-20">
            <gf-title>
              <div class="flex items-center">
                <span class="mr-10">需求资质要求</span>
                <a-tooltip>
                  <span slot="title">如选择“是”，需在下方“附件”处上传相关佐证材料</span>
                  <a-icon
                    type="question-circle"
                    theme="filled"
                    style="color: #ddd"
                  />
                </a-tooltip>
              </div>
            </gf-title>
          </div>
          <div class="text-sm">
            <div
              class="mb-20"
              v-for="(item, index) in model.demands"
              :key="index"
            >
              <div class="mb-10">{{ index + 1 }}.{{ item.Key }}</div>
              <a-radio-group v-model="item.Value">
                <a-radio :value="true">是</a-radio>
                <a-radio :value="false">否</a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div>
          <div class="mb-20">
            <gf-title>解决方案</gf-title>
          </div>
          <gf-form-item
            prop="people"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="拟投入人员数量"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input-number
              v-model="model.people"
              :min="0"
            ></gf-re-input-number>
          </gf-form-item>
          <gf-form-item
            prop="introduction"
            label="解决方案概述"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 500,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <gf-re-textarea
              :rows="4"
              v-model="model.introduction"
              :max="500"
            ></gf-re-textarea>
          </gf-form-item>
          <gf-form-item
            prop="description"
            label="资质要求说明"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 500,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <gf-re-textarea
              :rows="4"
              v-model="model.description"
              :max="500"
            ></gf-re-textarea>
          </gf-form-item>
          <gf-form-item
            prop="supplement"
            label="其他补充说明"
            :rules="[
              {
                max: 500,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <gf-re-textarea
              :rows="4"
              v-model="model.supplement"
              :max="500"
            ></gf-re-textarea>
          </gf-form-item>
          <gf-form-item prop="supplement" label="附件">
            <file-upload
              :size="20"
              accept=".pdf,.docx,.doc,.png,.jpg,.jpeg"
              v-model="model.attachments"
            ></file-upload>
          </gf-form-item>
          <gf-form-item
            prop="comment"
            label="单位意见"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
              {
                max: 500,
                message: '字数超出限制',
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <gf-re-textarea
              :rows="4"
              v-model="model.comment"
              :max="500"
            ></gf-re-textarea>
          </gf-form-item>
        </div>
        <div>
          <div class="mb-20">
            <gf-title>负责人信息</gf-title>
          </div>
          <gf-form-item
            prop="marketName"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="姓名"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.marketName"></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="marketPosition"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="单位职务"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.marketPosition"></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="marketEmail"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="Email"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.marketEmail"></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="marketPhone"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="手机号码"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.marketPhone"></gf-re-input>
          </gf-form-item>
        </div>
        <div>
          <div class="mb-20">
            <gf-title>联系人信息</gf-title>
          </div>
          <gf-form-item
            prop="operatorName"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="姓名"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.operatorName"></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="operatorPosition"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="单位职务"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.operatorPosition"></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="operatorEmail"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="Email"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.operatorEmail"></gf-re-input>
          </gf-form-item>
          <gf-form-item
            prop="operatorPhone"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['change', 'blur'],
              },
            ]"
            label="手机号码"
            :wrapperCol="{ span: 7 }"
          >
            <gf-re-input v-model="model.operatorPhone"></gf-re-input>
          </gf-form-item>
        </div>
      </a-form-model>
    </div>
    <div class="text-center">
      <slot v-bind="{ validate }" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponseForm",
  props: {
    rules: {
      type: Object,
      default: () => ({}),
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      model: {},
    };
  },
  created() {
    this.model = { ...this.dataSource };
  },
  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            resolve({ ...this.model });
          } else {
            reject();
          }
        });
      });
    },
  },
  watch: {
    dataSource(val) {
      this.model = val;
    },
  },
};
</script>