<template>
  <div class="p-20 rounded bg-white">
    <div class="relative">
      <div class="mb-20 text-left px-20">
        <a-steps :current="current">
          <a-step
            :status="detail.status === 99 ? 'error' : ''"
            :title="detail.status === 99 ? '审核失败' : '需求审核中'"
          ></a-step>
          <a-step title="进行中"></a-step>
          <a-step
            :status="
              detail.status === 30 && detail.evaluate === 2 && !detail.selected
                ? 'error'
                : ''
            "
            :title="
              detail.status === 30 && detail.evaluate === 2 && !detail.selected
                ? '对接失败'
                : '对接成功'
            "
          ></a-step>
        </a-steps>
      </div>
      <div v-if="updateFlag">
        <SupplyForm :dataSource="detail">
          <a-space :size="20" slot-scope="{ validate }">
            <a-button @click="update(false)">取消</a-button>
            <a-button size="large" type="primary" @click="submit(validate)">
              提交
            </a-button>
          </a-space>
        </SupplyForm>
      </div>

      <div v-else>
        <div class="p-20 border-eee bg-background rounded-lg mb-40">
          <div class="flex font-bold text-xl mb-20">
            <div style="width: calc(100% - 160px)">
              {{ requirement.name }}
            </div>
            <div class="text-right" style="color: #fc8a00; width: 160px">
              {{ requirement.budgetStr }}
            </div>
          </div>
          <div class="text-sm">
            <div class="mb-10 flex items-center">
              <div class="company-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">需求方:</div>
              <div>{{ requirement.publisher }}</div>
            </div>
            <div class="mb-10 flex items-center">
              <div class="industry-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">所在行业:</div>
              <div>
                <gf-tag-group>
                  <gf-tag
                    v-for="(tag, index) in requirement.industry"
                    :key="index"
                  >
                    {{ tag.Name }}
                  </gf-tag>
                </gf-tag-group>
              </div>
            </div>
            <div class="mb-10 flex items-center">
              <div class="range-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">应用范围:</div>
              <div>
                <gf-tag-group>
                  <gf-tag
                    v-for="(tag, index) in requirement.scene"
                    :key="index"
                  >
                    {{ tag.Name }}
                  </gf-tag>
                </gf-tag-group>
              </div>
            </div>
            <div class="mb-10 flex items-center">
              <div class="area-icon w-4 h-4 icon mr-1"></div>
              <div class="text-999 mr-2.5">所在区域:</div>
              <div>{{ requirement.regionStr }}</div>
            </div>
          </div>
        </div>
        <div class="mb-40">
          <SupplyDisplay :dataSource="detail" />
          <div class="text-center" v-if="detail.status > 0">
            <a-space :size="20">
              <a-button
                v-if="detail.status === 99"
                type="primary"
                @click="update(true)"
                >重新编辑</a-button
              >
              <a-button
                type="primary"
                v-if="
                  detail.status === 30 &&
                  detail.evaluate === 2 &&
                  detail.selected &&
                  reviewFlag
                "
                @click="openReview"
                >去评价</a-button
              >
            </a-space>
          </div>
        </div>
      </div>
    </div>
    <review-modal v-model="reviewVisible" @confirm="review"></review-modal>
  </div>
</template>

<script>
import SupplyForm from "./components/responseForm.vue";
import SupplyDisplay from "./components/responseDisplay.vue";
import SelectExpertModal from "../expert/modal.vue";
import {
  review,
  getExpertSupply,
  updateSupply,
  close,
  getReview,
  submitSupplyEvaluate,
} from "@/api/api/requirement";
export default {
  components: { SupplyForm, SelectExpertModal, SupplyDisplay },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.getExpertSupply(id);
  },
  data() {
    return {
      id: "",
      detail: {},
      expertSelectVisible: false,
      refuseVisible: false,
      updateFlag: false,
      comment: {},
      requirement: {},
      reviewVisible: false,
      reviewFlag: false,
    };
  },
  computed: {
    current() {
      const { status, evaluate, selected } = this.detail;
      if ([1, 10, 20].includes(status)) {
        return 0;
      } else if (status === 30 && [0, 1].includes(evaluate)) {
        return 1;
      } else if (status === 30 && evaluate === 2 && selected) {
        return 2;
      } else if (status === 30 && evaluate === 2 && !selected) {
        return 3;
      }
    },
  },
  methods: {
    openReview() {
      this.reviewVisible = true;
    },
    back() {
      this.$router.back();
    },
    // 修改
    update(state) {
      this.updateFlag = state;
    },
    // 更新需求
    submit(validate) {
      validate().then(async (res) => {
        this.updateSupply(this.id, res);
        if (this.detail.status === 99) {
          await this.submitSupplyEvaluate();
        }
        this.updateFlag = false;
        this.getExpertSupply(this.id);
      });
    },
    submitSupplyEvaluate() {
      return submitSupplyEvaluate(this.id);
    },
    // 获取需求接口
    getExpertSupply(id) {
      getExpertSupply(id)
        .then((res) => {
          this.detail = res;
          this.requirement = res.requirement;
          this.getReview(res.requirementID);
        })
        .catch(() => {
          this.$message.error("获取详情失败");
        });
    },
    // 更新需求接口
    updateSupply(id, res) {
      updateSupply(id, res)
        .then(() => {
          this.$message.success("更新需求成功");
        })
        .catch(() => {
          this.$message.error("更新需求失败");
        });
    },
    // 关闭需求
    close() {
      const id = this.id,
        $message = this.$message,
        back = this.back;
      this.$confirm({
        title: "是否关闭该需求",
        okText: "关闭",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          close(id)
            .then(() => {
              $message.success("关闭需求成功");
              back();
            })
            .catch(() => {
              $message.error("关闭需求失败");
            });
        },
      });
    },
    // 完成评价
    review(data) {
      review(this.detail.requirementID, data).then(() => {
        this.$message.success("评价成功");
        this.reviewVisible = false;
        this.reviewFlag = false;
      });
    },
    // 获取我的评价
    getReview(id) {
      getReview(id)
        .then(() => {
          this.reviewFlag = false;
        })
        .catch(() => {
          this.reviewFlag = true;
        });
    },
  },
};
</script>