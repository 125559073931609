var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-40"},[_c('a-form-model',{ref:"container",attrs:{"model":_vm.model,"rules":_vm.rules,"labelCol":{ span: 5 },"wrapperCol":{ span: 11 },"labelAlign":"left","colon":false}},[_c('div',{staticClass:"mb-40"},[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-10"},[_vm._v("需求资质要求")]),_c('a-tooltip',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("如选择“是”，需在下方“附件”处上传相关佐证材料")]),_c('a-icon',{staticStyle:{"color":"#ddd"},attrs:{"type":"question-circle","theme":"filled"}})],1)],1)])],1),_c('div',{staticClass:"text-sm"},_vm._l((_vm.model.demands),function(item,index){return _c('div',{key:index,staticClass:"mb-20"},[_c('div',{staticClass:"mb-10"},[_vm._v(_vm._s(index + 1)+"."+_vm._s(item.Key))]),_c('a-radio-group',{model:{value:(item.Value),callback:function ($$v) {_vm.$set(item, "Value", $$v)},expression:"item.Value"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1)}),0)]),_c('div',[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("解决方案")])],1),_c('gf-form-item',{attrs:{"prop":"people","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"拟投入人员数量","wrapperCol":{ span: 7 }}},[_c('gf-re-input-number',{attrs:{"min":0},model:{value:(_vm.model.people),callback:function ($$v) {_vm.$set(_vm.model, "people", $$v)},expression:"model.people"}})],1),_c('gf-form-item',{attrs:{"prop":"introduction","label":"解决方案概述","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },
            {
              max: 500,
              message: '字数超出限制',
              trigger: ['change', 'blur'],
            } ]}},[_c('gf-re-textarea',{attrs:{"rows":4,"max":500},model:{value:(_vm.model.introduction),callback:function ($$v) {_vm.$set(_vm.model, "introduction", $$v)},expression:"model.introduction"}})],1),_c('gf-form-item',{attrs:{"prop":"description","label":"资质要求说明","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },
            {
              max: 500,
              message: '字数超出限制',
              trigger: ['change', 'blur'],
            } ]}},[_c('gf-re-textarea',{attrs:{"rows":4,"max":500},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1),_c('gf-form-item',{attrs:{"prop":"supplement","label":"其他补充说明","rules":[
            {
              max: 500,
              message: '字数超出限制',
              trigger: ['change', 'blur'],
            } ]}},[_c('gf-re-textarea',{attrs:{"rows":4,"max":500},model:{value:(_vm.model.supplement),callback:function ($$v) {_vm.$set(_vm.model, "supplement", $$v)},expression:"model.supplement"}})],1),_c('gf-form-item',{attrs:{"prop":"supplement","label":"附件"}},[_c('file-upload',{attrs:{"size":20,"accept":".pdf,.docx,.doc,.png,.jpg,.jpeg"},model:{value:(_vm.model.attachments),callback:function ($$v) {_vm.$set(_vm.model, "attachments", $$v)},expression:"model.attachments"}})],1),_c('gf-form-item',{attrs:{"prop":"comment","label":"单位意见","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            },
            {
              max: 500,
              message: '字数超出限制',
              trigger: ['change', 'blur'],
            } ]}},[_c('gf-re-textarea',{attrs:{"rows":4,"max":500},model:{value:(_vm.model.comment),callback:function ($$v) {_vm.$set(_vm.model, "comment", $$v)},expression:"model.comment"}})],1)],1),_c('div',[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("负责人信息")])],1),_c('gf-form-item',{attrs:{"prop":"marketName","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"姓名","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.marketName),callback:function ($$v) {_vm.$set(_vm.model, "marketName", $$v)},expression:"model.marketName"}})],1),_c('gf-form-item',{attrs:{"prop":"marketPosition","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"单位职务","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.marketPosition),callback:function ($$v) {_vm.$set(_vm.model, "marketPosition", $$v)},expression:"model.marketPosition"}})],1),_c('gf-form-item',{attrs:{"prop":"marketEmail","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"Email","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.marketEmail),callback:function ($$v) {_vm.$set(_vm.model, "marketEmail", $$v)},expression:"model.marketEmail"}})],1),_c('gf-form-item',{attrs:{"prop":"marketPhone","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"手机号码","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.marketPhone),callback:function ($$v) {_vm.$set(_vm.model, "marketPhone", $$v)},expression:"model.marketPhone"}})],1)],1),_c('div',[_c('div',{staticClass:"mb-20"},[_c('gf-title',[_vm._v("联系人信息")])],1),_c('gf-form-item',{attrs:{"prop":"operatorName","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"姓名","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.operatorName),callback:function ($$v) {_vm.$set(_vm.model, "operatorName", $$v)},expression:"model.operatorName"}})],1),_c('gf-form-item',{attrs:{"prop":"operatorPosition","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"单位职务","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.operatorPosition),callback:function ($$v) {_vm.$set(_vm.model, "operatorPosition", $$v)},expression:"model.operatorPosition"}})],1),_c('gf-form-item',{attrs:{"prop":"operatorEmail","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"Email","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.operatorEmail),callback:function ($$v) {_vm.$set(_vm.model, "operatorEmail", $$v)},expression:"model.operatorEmail"}})],1),_c('gf-form-item',{attrs:{"prop":"operatorPhone","rules":[
            {
              required: true,
              message: '请输入',
              trigger: ['change', 'blur'],
            } ],"label":"手机号码","wrapperCol":{ span: 7 }}},[_c('gf-re-input',{model:{value:(_vm.model.operatorPhone),callback:function ($$v) {_vm.$set(_vm.model, "operatorPhone", $$v)},expression:"model.operatorPhone"}})],1)],1)])],1),_c('div',{staticClass:"text-center"},[_vm._t("default",null,null,{ validate: _vm.validate })],2)])}
var staticRenderFns = []

export { render, staticRenderFns }